import React from "react";
import { Link } from "gatsby";
import logo from "../../../images/ci/drone_icons_sponsor.svg";
const HeroBanner = ({ title, paragraph, media, sponsor = false }) => {
  return (
    <section className="hero-banner">
      <div className="hero-banner__space">
        <h1 className="hero-banner__space__title">{title}</h1>
        {sponsor && (
          <div className="hero-banner__sponsor">
            <p>Powered by</p>
            <img
              className="hero-banner__sponsor__img"
              src={logo}
              alt="Sponsor by drone"
            />
          </div>
        )}

        <p className="hero-banner__space__description">{paragraph}</p>
        <Link to="/inquiry/" className="hero-banner__btn">
          お問い合わせ
        </Link>
      </div>
      <div className="hero-banner__space">
        <div className="hero-banner__media">{media}</div>
      </div>
    </section>
  );
};
export default HeroBanner;
