import React from "react";
import { functions } from "../contents";

const FeatureFlagsFunctions = () => {
  return (
    <section className="feature-flags-functions">
      <h1 className="feature-flags-functions__title">
        Harness Feature Flagsの特徴
      </h1>
      <div className="feature-flags-functions__cards">
        {functions.map((funct) => (
          <div className="feature-flags-functions__cards__item" key={funct.id}>
            {funct.icon}
            <h2 className="feature-flags-functions__cards__item__title">
              {funct.title}
            </h2>
            <p className="feature-flags-functions__cards__item__body">
              {funct.body}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeatureFlagsFunctions;
