import React from "react";
import { features } from "../contents";
import { StaticImage } from "gatsby-plugin-image";

const FeatureFlagsIntroduction = () => {
  return (
    <section className="feature-flags-intro">
      <div className="feature-flags-intro__head">
        <div className="feature-flags-intro__head__image">
          <StaticImage
            src="../../../images/feature-flags/ff-intro-head-img.png"
            width={660}
            height={400}
            alt="Feature Flagsの概要"
            placeholder="dominantColor"
            quality={100}
          />
        </div>
        <div className="feature-flags-intro__head__content">
          <div className="feature-flags-intro__head__content__title">
            <span className="icon">
              <StaticImage
                src="../../../images/feature-flags/ff-icon.svg"
                width={48}
                height={48}
                alt="Feature Flags"
                placeholder="dominantColor"
                quality={100}
              />
              {/* <img
                src="../../../images/feature-flags/ff-icon.svg"
                width="48"
                height="48"
                alt="Feature Flags"
                loading="lazy"
              /> */}
            </span>
            <span className="text">Feature Flagsの概要 </span>
          </div>
          <p className="feature-flags-intro__head__content__description">
            真のスピードとコントロールで <br /> 自信を持ってデリバリー
          </p>
        </div>
      </div>
      <div className="feature-flags-intro__body">
        <div className="title">シンプルでスケーラブルな機能管理</div>
        <div className="feature-flags-intro__body__main">
          {features.map((feature) => (
            <article key={feature.id}>
              <div className="mb-4">{feature.icon}</div>
              <div className="feature-flags-intro__body__main__heading">
                {feature.title}
              </div>
              <div className="feature-flags-intro__body__main__body">
                {feature.body}
              </div>
            </article>
          ))}
        </div>
        <div className="flex flex-col md:flex-row items-center mb-[120px]">
          <StaticImage
            src="../../../images/feature-flags/ff-feature-one-img1.png"
            width={600}
            height={300}
            alt="スケーラブル、セキュア、高性能"
            placeholder="dominantColor"
            quality={100}
          />
          <div className="ml-0 md:ml-14">
            <div className="hn-h3 leading-8 font-bold mb-8">
              スケーラブル、セキュア、高性能
            </div>
            <ul className="list-disc font-normal text-[16px] leading-6 ml-8">
              <li>
                プラットフォーム全体のRBACによるアクセス制御と監査ログによる可視化
              </li>
              <li>
                レスポンスタイム500ms以下のリアルタイムストリーミングアーキテクチャー
              </li>
              <li>
                お客様のアプリケーションのパフォーマンスやセキュリティーのリスクとならない設計
              </li>
              <li>フルマネージドSaaS版およびセルフマネージドオンプレミス版</li>
              <li>
                耐障害性の強化が必要なチームには、リレープロキシーサービスを提供
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-y-10 md:gap-x-16 items-center">
          <div className="w-full md:w-1/2">
            <div className="text-base font-bold">オープンソースSDK</div>
            <div className="hn-h3 leading-8 font-bold my-6">
              あらゆる主要言語をサポート
            </div>
            <p className="text-lg font-normal leading-6">
              ツールのインテグレーションはチームに必要不可欠です。Harness
              Feature
              Flagsは、お客様のスタック全体と統合し、オーケストレーションします。
            </p>
          </div>
          <StaticImage
            src="../../../images/feature-flags/ff-feature-two-img.png"
            width={280}
            height={240}
            alt="スケーラブル、セキュア、高性能"
            placeholder="dominantColor"
            quality={100}
          />
          <StaticImage
            src="../../../images/feature-flags/ff-feature-three-img.png"
            width={280}
            height={240}
            alt="スケーラブル、セキュア、高性能"
            placeholder="dominantColor"
            quality={100}
          />
        </div>
      </div>
    </section>
  );
};

export default FeatureFlagsIntroduction;
