import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Loader from "../../../images/loading.inline.svg";

const VideoLightBox = ({ src, title, isVideoPlaying, toggleVideoPlaying }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isVideoPlaying && typeof window !== "undefined") {
      document.body.classList.add("anti-scroll");
    }
    return () => document.body.classList.remove("anti-scroll");
  }, [isVideoPlaying]);
  return (
    <div className="video-lightbox">
      <div className="video-lightbox__iframe-container">
        <iframe
          loading="lazy"
          src={src}
          title={title}
          width="100%"
          height="100%"
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          onLoad={() => setIsLoading(false)}
        ></iframe>
      </div>
      {isLoading && (
        <div className="video-lightbox__loading-gif">
          <Loader width={"60px"} height={"60px"} />
        </div>
      )}
      <button
        className="video-lightbox__close-btn"
        onClick={() => toggleVideoPlaying(false)}
      >
        <FaTimes color="#fff" size={28} />
      </button>
    </div>
  );
};

export default VideoLightBox;
