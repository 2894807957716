import React, { useState } from "react";
import Container from "@layout/container";
import HeroBanner from "../../components/common/heroBanner";
import FeatureFlagsIntroduction from "../../components/featureFlags/FeatureFlagsIntroduction/FeatureFlagsIntroduction";
import FeatureFlagsFunctions from "../../components/featureFlags/FeatureFlagsFunctions/FeatureFlagsFunctions";
import VideoLightBox from "../../components/common/videoLightBox/VideoLightBox";
import Meta from "../../components/seo/Meta";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const FeatureFlags = (props) => {
  const [isVideoPlaying, toggleVideoPlaying] = useState(false);
  const siteUrl = props?.data?.allSite?.nodes[0].siteMetadata?.siteUrl;
  const metaData = {
    title: `Harness Feature Flags｜Harness正規販売代理店 DXable`,
    description: `Harness Feature Flagsは、開発者向けのインテリジェンス、自動化、およびUXを備え、機能フラグのパイプラインを自動化し、機能の公開と依存関係のセキュアな管理を実現します。 CI/CDをFeature Flagsパイプラインに拡張して、シームレスなワークフローと可視性を得ることも可能です。`,
    keywords: `Harness,Feature Flags,機能フラグ,harness,Harness.io,harness.io,ハーネス,DevOps,Harness代理店,YAML,GitOps`,
    url: `${siteUrl}${props.path}`,
  };
  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <HeroBanner
          title={<BannerTitle />}
          paragraph={<BannerParagraph />}
          media={
            <MediaVideo
              isVideoPlaying={isVideoPlaying}
              toggleVideoPlaying={toggleVideoPlaying}
            />
          }
        />
        <section className="w-fit mb-[120px] mx-auto">
          <StaticImage
            src="../../images/feature-flags/ff-banner-img.png"
            width={1180}
            height={460}
            alt="Feature Flags Banner Image"
            placeholder="dominantColor"
            quality={100}
          />
        </section>
        <FeatureFlagsIntroduction />
        <FeatureFlagsFunctions />
      </Container>
    </>
  );
};

const BannerTitle = () => {
  return <span>Harness Feature Flags</span>;
};

const BannerParagraph = () => {
  return (
    <>
      <span className="mb-4">
        スピードとコントロールを両立させて、自信を持って新機能をデプロイ。
      </span>
      <br />
      <span>
        新機能の実装を60%高速化しつつ、エンタープライズクラスのコントロールとガバナンスにより、本番環境でもデプロイリスクを低減できます。
      </span>
    </>
  );
};

const MediaVideo = ({ isVideoPlaying, toggleVideoPlaying }) => {
  return (
    <>
      <button onClick={() => toggleVideoPlaying(!isVideoPlaying)}>
        <StaticImage
          src="../../images/feature-flags/featureFlagBanner.svg"
          layout="fullWidth"
          loading="eager"
          alt="Feature Flags Page Panner"
          className="w-full hover:cursor-pointer"
          quality={100}
        />
      </button>
      {isVideoPlaying && (
        <VideoLightBox
          src={"https://www.youtube.com/embed/nm40rzomauM"}
          title={"Feature Flags in 3 mins"}
          toggleVideoPlaying={toggleVideoPlaying}
          isVideoPlaying={isVideoPlaying}
        />
      )}
    </>
  );
};

export const featureFlagsPageData = graphql`
  query fetchFeatureFlagsPageData {
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;

export default FeatureFlags;
