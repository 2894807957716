import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import video1 from "../../images/feature-flags/comming-soon-feature.mp4";
import AnyLanguageIcon from "../../images/feature-flags/any-language-icon.inline.svg";
import CloudNativeIcon from "../../images/feature-flags/cloud-native.inline.svg";
import GitOpsIcon from "../../images/feature-flags/gitops.inline.svg";
import CfIcon from "../../images/feature-flags/CF-Logo.inline.svg";
import EasyInstallIcon from "../../images/feature-flags/easy-install.inline.svg";

export const features = [
  {
    id: 1,
    icon: (
      <StaticImage
        src="../../images/feature-flags/ff-feature-one-icon.svg"
        width={80}
        height={60}
        placeholder="dominantColor"
        alt="新機能デリバリーの基本"
        quality={100}
      />
    ),
    title: "新機能デリバリーの基本",
    body: (
      <span>
        柔軟なユーザーターゲット、複数のフラグタイプ、管理ダッシュボードで、フィーチャーフラグ管理の基本要素の全てを提供します。
        <br />
        <br />
        複雑なセットアップや管理スキーマを必要とせず、すぐに機能管理を開始できます。
      </span>
    ),
  },
  {
    id: 2,
    icon: (
      <StaticImage
        src="../../images/feature-flags/ff-feature-two-icon.svg"
        width={80}
        height={60}
        placeholder="dominantColor"
        alt="CI/CDとのネイティブ統合"
        quality={100}
      />
    ),
    title: "CI/CDとのネイティブ統合",
    body: (
      <span>
        チームに共通のデリバリープラットフォームを提供し、管理を簡素化し、ミッションクリティカルなソフトウェアデリバリープロセスにおけるリスクの発生を回避できます。
        <br />
        <br />
        ビルドからデプロイ、リリースまで、エンドツーエンドの可視化と制御を実現します。グローバルなガバナンス、管理、監査が全て1か所で行えます。
      </span>
    ),
  },
  {
    id: 3,
    icon: (
      <StaticImage
        src="../../images/feature-flags/ff-feature-three-icon.svg"
        width={80}
        height={60}
        placeholder="dominantColor"
        alt="自動化された機能リリースパイプライン"
        quality={100}
      />
    ),
    title: "自動化された機能リリースパイプライン",
    body: (
      <span>
        スケジューリング、承認、トリガー、プログレッシブロールアウト、ヘルスチェックなど、全てを自動化します。
        <br />
        <br />
        機能リリースパイプラインを使用して、典型的なリリースプロセスをテンプレート化および自動化できる反復可能なワークフローに統合できます。
      </span>
    ),
  },
  {
    id: 4,
    icon: (
      <StaticImage
        src="../../images/feature-flags/ff-feature-four-icon.svg"
        width={80}
        height={60}
        placeholder="dominantColor"
        alt="アナリティクスとビジネスインテリジェンス"
        quality={100}
      />
    ),
    title: "アナリティクスとビジネスインテリジェンス",
    body: (
      <span>
        基本的な分析により、フィーチャーフラグの使用状況と影響を追跡できます。さらに、Harnessプラットフォーム上のソフトウェアデリバリープロセス全体を把握することで、分析力を向上させることができます。
        <br />
        <br />
        フィーチャーフラグがいかに目的を前進させているか、常に把握することができます。
      </span>
    ),
  },
  {
    id: 5,
    icon: (
      <StaticImage
        src="../../images/feature-flags/ff-feature-five-icon.svg"
        width={80}
        height={60}
        placeholder="dominantColor"
        alt="開発者向け公開API"
        quality={100}
      />
    ),
    title: "開発者向け公開API",
    body: (
      <span>
        開発者はAPIを使用してフィーチャーフラグの作成、編集、および管理を全てコードで行うことができます。UIやダッシュボードは便利ですが、日々の作業の大部分には必要ありません。
        <br />
        <br />
        開発者の管理エクスペリエンスを簡素化し、開発者の創造性を活用して、機能管理をさらに簡単かつ自動化することができます。
      </span>
    ),
  },
  {
    id: 6,
    icon: (
      <StaticImage
        src="../../images/feature-flags/ff-feature-six-icon.svg"
        width={80}
        height={60}
        placeholder="dominantColor"
        alt="機能管理のためのグローバルガバナンス"
        quality={100}
      />
    ),
    title: "機能管理のためのグローバルガバナンス",
    body: (
      <span>
        Harness Policy
        Engineを使用してガードレールを作成し、全ての機能リリースにグローバルガバナンスポリシーを適用。開発チームがこれまで以上に迅速に行動できるようになります。
        <br />
        <br />
        スピードを犠牲にすることなく、ローカルおよびグローバルにガバナンスの基準を適用できます。
      </span>
    ),
  },
];

export const functions = [
  {
    id: 1,
    title: "CI/CDとFeature Flagsにまたがるガバナンス、管理、監査",
    body: (
      <span>
        最初のコミットからデプロイ後のロールアウトまで、可視化、分析、およびガバナンスを実現します。Harness
        Policy
        Engineに組み込まれたグローバルガバナンス、共有環境、パーミッション、きめ細かいRBACにより、再作業を回避できます。
        <br />
        <br />
        CI/CDをフィーチャーフラグパイプラインに拡張し、シームレスなエンドツーエンドのワークフロー、ガバナンス、可視性を実現できます。
      </span>
    ),
    icon: (
      <StaticImage
        src="../../images/feature-flags/ff-function-one-icon.svg"
        width={80}
        height={60}
        placeholder="dominantColor"
        alt="CI/CDとFeature Flagsにまたがるガバナンス、管理、監査"
        quality={100}
      />
    ),
  },
  {
    id: 2,
    title: "自動化されたリリースパイプライン",
    body: (
      <span>
        テスト、スケジューリング、承認、通知などをまとめた柔軟なパイプラインを構築できます。Jira、Slack、ServiceNowなどのワークフローツールに統合して、プロセス全体の自動化が可能です。
        <br />
        <br />
        再利用可能な機能リリーステンプレートを作成することで、機能間の標準化を行い、アプリケーション、サービス、チーム間での開発者の労力が軽減されます。
      </span>
    ),
    icon: (
      <StaticImage
        src="../../images/feature-flags/ff-function-two-icon.svg"
        width={80}
        height={60}
        placeholder="dominantColor"
        alt="自動化されたリリースパイプライン"
        quality={100}
      />
    ),
  },
  {
    id: 3,
    title: "機械学習によるプログレッシブデリバリー",
    body: (
      <span>
        APMメトリクスとログイベントを統合して、フラグの健全性を検証できます。自動化されたトリガーを作成して、望ましくない結果のフラグをオフにし、要件を満たす機能を段階的にロールアウトできます。
        <br />
        <br />
        アプリケーションの健全性に基づいてフラグの変更を自動的に制御。少ない労力でフラグの動作がスマートかつリスクフリーになります。
      </span>
    ),
    icon: (
      <StaticImage
        src="../../images/feature-flags/ff-function-three-icon.svg"
        width={80}
        height={60}
        placeholder="dominantColor"
        alt="機械学習によるプログレッシブデリバリー"
        quality={100}
      />
    ),
  },
  {
    id: 4,
    title: "YAMLとGitOpsによるフラグ管理",
    body: (
      <span>
        完全なREST
        APIを使用してフィーチャーフラグを作成および処理し、フラグ設定をシンプルなYAMLファイルとしてGitに保存および管理することで、任意の優先環境においてバージョン管理および監査を簡単に行うことができます。
        <br />
        <br />
        フィーチャーフラグを既存の開発者のワークフローに統合し、機能をすぐにリリースできるようになります。
      </span>
    ),
    icon: (
      <StaticImage
        src="../../images/feature-flags/ff-function-four-icon.svg"
        width={80}
        height={60}
        placeholder="dominantColor"
        alt="YAMLとGitOpsによるフラグ管理"
        quality={100}
      />
    ),
  },
];
